import React from 'react'
import {
  HomeServices,
  LandingHero,
  Page,
  References,
} from '@components'

const meta = {
  title: 'Protupožarna zaštita - FSB d.o.o.',
  description: 'Optimalna rješenja pasivne protupožarne zaštite. FSB d.o.o. nudi široku paleta proizvoda te izvođenje rješenja pasivne protupožarne zaštite.',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <LandingHero />
    <HomeServices />
    <References />
  </Page>
)

export default Homepage